"use client";

import { X } from "lucide-react";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";

const AnnouncementBanner = () => {
  const [mounted, setMounted] = useState(false);
  const [display, setDisplay] = useState(true);
  const settings = {
    className: "slider variable-width",
    variableWidth: true,
    arrows: false,
    infinite: true,
    speed: 20000,
    autoplaySpeed: 0.5,
    slidesToScroll: 1,
    autoplay: true,
  };

  const list = [
    {
      message:
        "Lifetime featuring on our landing page to the top 200 talents with fully updated profile, cbt test score and softskill interview score.",
    },
    {
      message:
        "A free droomwork account for two (2) years for the first 10 users with fully updated profile, cbt test score and softskill interview score.",
    },
  ];

  useEffect(() => {
    if (!mounted) setMounted(true);
  }, []);

  if (!mounted || !display) return null;

  return (
    <div className="relative flex w-full items-center overflow-hidden bg-orange-500 py-3 text-xs md:text-base">
      <Slider {...settings}>
        {[...list, ...list, ...list].map((each, i) => (
          <p
            key={i}
            className="mx-2 flex w-fit items-center font-medium text-white"
          >
            <span className="whitespace-pre text-blue-950">{each.message}</span>
          </p>
        ))}
      </Slider>

      <div className="absolute right-2 z-10 bg-white rounded-full p-2">
        <X className="w-5 h-5 hover:cursor-pointer" onClick={() => setDisplay(false)} />
      </div>
    </div>
  );
};

export default AnnouncementBanner;
