import { Fragment, useEffect, useState } from "react";
import "./App.css";
import PageLoader from "./components/Loader/PageLoader";
import AllRoutes from "./config/routes";
import { ThemeProvider } from "./context/ThemeContext";
import useHandleLogout from "./hooks/useHandleLogout";
import {
  usePushNotificationMutation,
  useUserProfileMutation,
} from "./lib/endpoints/auth/auth.api";
import { addUserProfile, selectUserState } from "./lib/features/auth/authSlice";
import { useAppDispatch, useTypedSelector } from "./lib/store/store";
import CalendlyScheduleModal from "./components/Modal/CalendlySchedule";
import { generateToken, messaging } from "./lib/utils/firebase";
import { onMessage } from "firebase/messaging";

export const TOKEN = "droomwork-talent-token";

function App() {
  const userState = useTypedSelector(selectUserState);
  const dispatch = useAppDispatch();
  const { getProfile } = useUserProfileMutation();
  const { subscribe } = usePushNotificationMutation();
  const { handleLogout } = useHandleLogout();
  const [modal, setModal] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  // console.log("userState", userState);
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEsImlhdCI6MTcxNTk3NzExNn0.AjRrQRbdB8ZHoksRjN5IwldUpPYpxv8pj8p03CqhhIs
  // ebuka eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjMxLCJpYXQiOjE3MjcyNTQ2NDh9.PXvxYpogBcxjhMaoskuADmrX0pVEYWz4hT5gRVkcIhU

  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjExLCJpYXQiOjE3Mjg5MTE5NjZ9.QsxVsqv1sNWHcfppR3IfS_q9JZPkWEC0i8RtoZmA6s8
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjE0LCJpYXQiOjE3Mjk0MTgzNDh9.DUnkryT0Qn5lLhbL7Ge1lVwKa65zWH_5UBoTw0n8efs

  const handlePushNotificationToken = async () => {
    const token = await generateToken();

    if (!token) return;

    const formData = new FormData();
    formData.append("push_token", token);

    try {
      !isSubscribed &&
        subscribe(formData, {
          onSuccess: (res) => {
            setIsSubscribed(true);
            // console.log('response ', res)
          },
          onError: (error: any) => {
            console.log("error ", error);
          },
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMounted && !!userState.accessToken) {
      handlePushNotificationToken();
      onMessage(messaging, (payload) => {
        new Notification(payload.notification?.title ?? "", {
          icon: payload.notification?.icon ?? "./logo.png",
          body: payload.notification?.body ?? "",
        });
      });
    } else {
      setIsMounted(true);
    }
  }, [isMounted, userState]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    try {
      if (params.has("token")) {
        const formData = new FormData();
        const token = params.get("token")?.trim() ?? "";
        formData.append("userToken", token);
        getProfile(formData, {
          onSuccess: (res) => {
            const userInfo = {
              accessToken: res.accessToken,
              user: {
                ...res.user.data[0],
                extraDetail: res.user.data[1],
              },
            };
            dispatch(addUserProfile(userInfo));
          },
          onError: (error: any) => {
            // console.log("error", error);
          },
        });
        localStorage.setItem(TOKEN, token ?? "");
      } else if (localStorage.getItem(TOKEN)) {
        if (userState.accessToken) return;

        const formData = new FormData();
        formData.append("userToken", localStorage.getItem(TOKEN) ?? "");
        getProfile(formData, {
          onSuccess: (res) => {
            const userInfo = {
              accessToken: res.accessToken,
              user: {
                ...res.user.data[0],
                extraDetail: res.user.data[1],
              },
            };
            dispatch(addUserProfile(userInfo));
          },
          onError: (error: any) => {
            // console.log("error", error);
          },
        });
      } else {
        handleLogout();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  if (userState.accessToken === null) return <PageLoader />;
  if (userState?.user?.role !== "developer")
    return (
      <Fragment>
        <PageLoader />
      </Fragment>
    );

  return (
    <ThemeProvider>
      <div className="relative">
        <AllRoutes />
        {!userState.user.is_interviewed && modal && (
          <CalendlyScheduleModal close={() => setModal(false)} open />
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
