import { format } from "date-fns";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { ThemeContext } from "src/context/ThemeContext";
import useAlert from "src/hooks/use-alert";
import { useTransactionsDetailsQuery } from "src/lib/endpoints/wallet/wallet.api";

type Props = {
  open: boolean;
  close: () => void;
  data: any;
};

const WalletTransactionDetailModal = ({ open, close, data }: Props) => {
  const { theme } = useContext(ThemeContext);
  const [error, setError] = useState("");
  const { state, open: openAlert, close: closeAlert, Alert } = useAlert();
  const { transaction } = useTransactionsDetailsQuery(data?.id ?? "");

  useEffect(() => {
    if (error) {
      openAlert({
        ...state,
        title: error,
        variant: "error",
        active: true,
      });
    }
  }, [error]);

  if (!transaction) return null

  return (
    <Fragment>
      <div
        className={`z-30 ${
          open ? "flex" : "flex -translate-y-full"
        } transition-ease fixed inset-x-0 -top-10 bottom-0 w-full items-center justify-center overflow-auto bg-black/60`}
      >
        <section
          className={`relative z-20 flex flex-col space-y-3 overflow-hidden rounded-lg px-2 py-5 bg-white ${
            theme === "dark" ? "shadow-card-dark" : "shadow-card"
          }`}
        >
          <div
            onClick={() => close()}
            className="absolute right-5 top-5 block hover:cursor-pointer"
          >
            <GrFormClose className="h-6 w-6" />
          </div>

          <div className="h-[70vh] min-w-[60vh] max-w-[95%] w-full md:w-[50vw] hidden-scrollbar overflow-hidden overflow-y-auto rounded-lg px-1.5 md:top-0 mx-auto md:px-3">
            <h1 className="text-xl mb-14 font-semibold">Withdrawal details</h1>
            <div className="flex flex-col gap-5">
              <div className="flex justify-between gap-10">
                <p className="whitespace-nowrap ">Status</p>
                <p
                  className={`${
                    theme === "dark" ? "text-white" : "text-black"
                  } text-lg`}
                >
                  {transaction?.status ?? "Nil"}
                </p>
              </div>
              <div className="flex justify-between gap-10">
                <p className="whitespace-nowrap ">Date</p>
                <p
                  className={`${
                    theme === "dark" ? "text-white" : "text-black"
                  } text-lg`}
                >
                  {format(transaction?.created_at ?? Date.now(), "EEE, MMM d")}
                </p>
              </div>
              <div className="flex justify-between gap-10">
                <p className="whitespace-nowrap ">Amount</p>
                <p
                  className={`${
                    theme === "dark" ? "text-white" : "text-black"
                  } text-lg`}
                >
                  ${transaction?.amount ?? "0"}
                </p>
              </div>
              {/* <div className="flex justify-between gap-10">
                <p className="whitespace-nowrap ">Account name</p>
                <p
                  className={`${
                    theme === "dark" ? "text-white" : "text-black"
                  } text-lg flex justify-end`}
                >
                  Drew Ofuenweuche
                </p>
              </div> */}
              <div className="flex justify-between gap-10">
                <p className="whitespace-nowrap ">Account number</p>
                <p
                  className={`${
                    theme === "dark" ? "text-white" : "text-black"
                  } text-lg`}
                >
                  {transaction?.account_number ?? "Nil"}
                </p>
              </div>
              <div className="flex justify-between gap-10">
                <p className="whitespace-nowrap ">Bank</p>
                <p
                  className={`${
                    theme === "dark" ? "text-white" : "text-black"
                  } text-lg`}
                >
                  {transaction?.bank_name ?? "Nil"}
                </p>
              </div>
              <div className="flex justify-between gap-10">
                <p className="whitespace-nowrap ">Transaction Ref</p>
                <p
                  className={`${
                    theme === "dark" ? "text-white" : "text-black"
                  } text-lg`}
                >
                  {transaction?.transaction_ref ?? "Nil"}
                </p>
              </div>

              <div className="flex justify-between gap-10">
                <p className="whitespace-nowrap ">Message</p>
                <p
                  className={`${
                    theme === "dark" ? "text-white" : "text-black"
                  } text-lg w-[50%] text-right flex-wrap text-wrap`}
                >
                  {transaction?.complete_message
                    ? transaction?.complete_message
                    : "Nil"}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <span className="fixed right-3 top-3 z-30">
        <Alert />
      </span>
    </Fragment>
  );
};

export default WalletTransactionDetailModal;
