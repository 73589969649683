import { formatDistanceToNow } from 'date-fns';
import { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useUserNotificationsQuery, useUserReadAllNotificationsMutation, useUserReadNotificationsMutation } from 'src/lib/endpoints/dashboard/dashboard.api';
import { ScrollArea } from "src/components/ui/scroll-area";
import ControlledModal from '../Modal/ControlledModal';
import Button from '../Button';
import Divider from '../Divider';
import useAlert from "src/hooks/use-alert";


const Notification = ({ open, callback }) => {
  const [page, setPage] = useState(1);
  const { notifications } = useUserNotificationsQuery(page)
  const [lastPage, setLastPage] = useState(1);
  const {
    state: alertState,
    open: openAlert,
    close: closeAlert,
    Alert,
  } = useAlert();
  const [selectedNotificationId, setSelectedNotificationId] = useState(0)
  const { readNotification } = useUserReadNotificationsMutation(selectedNotificationId)
  const { readNotifications } = useUserReadAllNotificationsMutation()

  let contentStyle = {
    padding: 0,
    backgroundColor: 'white',
    width: 'fit-content',
    boxShadow: 'none',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    right: 0,
    height: '100vh',
  };

  const markAllAsRead = async () => {
    readNotifications({}, {
      onSuccess: async (res) => {
        openAlert({
          ...alertState,
          title: 'Notifications read',
          variant: "success",
          active: true,
        });
      },
      onError: (error) => {
        openAlert({
          ...alertState,
          title: error?.response?.data?.message ?? error?.message ?? "",
          variant: "error",
          active: true,
        });
      },
    })
  }

  const markAsRead = async () => {
    readNotification({}, {
      onSuccess: () => { },
      onError: () => { },
    })
  }

  useEffect(() => {
    if (notifications) {
      setLastPage(notifications.lastPage)
    }
  }, [notifications])

  useEffect(() => {
    if (selectedNotificationId === 0) return
    markAsRead()
  }, [selectedNotificationId])

  return (
    <ControlledModal open={open} contentStyle={contentStyle}>
      <div className='w-[100vw] lg:w-[530px] h-screen px-4 lg:px-6 pt-8 pb-20 flex flex-col space-y-5'>
        <div className='w-full flex justify-end'>
          <IoClose className='h-7 w-7 items-end justify-end text-black cursor-pointer' onClick={callback} />
        </div>

        <div className='justify-between flex text-black'>
          <h2 className='text-xl lg:text-2xl'>Notifcations</h2>
          <p className='hover:cursor-pointer' onClick={markAllAsRead}>Mark all as read</p>
        </div>

        {/* Notification Card */}
        <ScrollArea className="flex flex-col space-y-3 h-fit max-h-full">
          <Divider className={''} />
          {notifications?.data?.map((notification, idx) => (
            <>
              <div onClick={() => { setSelectedNotificationId(prev => prev === notification.id ? 0 : notification.id), markAsRead() }} className={`flex space-x-5 py-3 hover:cursor-pointer ${notification.is_read ? 'text-gray-400' : 'text-primary'}`}>
                <div className='w-12 h-12'>
                  <img
                    src={notification?.sender_avatar ?? ''}
                    className=''
                    alt='Avatar'
                  />
                </div>
                <div>
                  <p className='font-semibold'>{notification.title}</p>
                  <p className='text-sm'>{formatDistanceToNow(new Date(notification.created_at ?? Date.now()), { addSuffix: true })}</p>
                </div>
              </div>
              {notification.id === selectedNotificationId && (
                <div className='text-primary'>{notification.body}</div>
              )}
              <Divider className={''} />
            </>
          ))}
        </ScrollArea>

        <div className='flex justify-between text-white w-full'>
          {page > 1 && <Button
            label={'Prev'}
            className={'bg-primary'}
            onClick={() => setPage(prev => prev - 1 > 0 ? prev - 1 : prev)}
          />}
          {page < lastPage && <Button
            label={'Next'}
            className={'bg-primary'}
            onClick={() => setPage(prev => prev + 1 <= lastPage ? prev + 1 : prev)}
          />}
        </div>

        <Alert />
      </div>
    </ControlledModal>
  );
};

export default Notification;
