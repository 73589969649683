import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PageIntro from 'src/components/PageIntro/PageIntro';
import Status from 'src/components/Status/Status';
import { useTransactionsQuery } from 'src/lib/endpoints/wallet/wallet.api';
import { startCase } from 'src/utils/utils';
import WalletTransactionDetailModal from '../../components/Modal/WalletTransactionDetailModal';
import Table from '../../components/Table/Table';
import Pagination from "src/components/Pagination";

const TransactionTable = () => {
  const siblingCount = 1;
  const pageColumn = 1;
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(1000);
  const { transactions } = useTransactionsQuery(page + 1)
  const [selectedData, setSelectedData] = useState()
  const { pathname } = useLocation();

  const tableHeader = useMemo(
    () => [
      {
        title: 'Type',
        key: 'type',
      },
      {
        title: 'Amount',
        key: 'amount',
      },
      {
        title: 'Date',
        key: 'date',
      },
      {
        title: 'Status',
        key: 'status',
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    (_page) => {
      setPage(_page - 1);
    },
    [page]
  );

  const modifiedData = useMemo(() => {
    return transactions?.data?.map((each) => [startCase(each?.type) ?? '', each?.amount ?? '0', format(each?.created_at ?? new Date().getTime(), "EEE, MMM d"), <Status label={each?.status ?? ''} />,
    <div
      className='inline-block rounded-md py-[2px] px-3 border border-[#575555] text-[#8D8989]'
      onClick={() => setSelectedData(each)}
    >
      Details
    </div>]);
  }, [transactions]);

  useEffect(() => {
    setTotalCount(transactions?.total ?? 0);
  }, [transactions]);

  if (!modifiedData) return null

  return (
    <div>
      {!pathname.includes("transactions") ? (
        <div className="flex justify-end">
          <Link
            to={"/transactions"}
            className="underline underline-offset-2 font-medium my-2 w-fit"
          >
            See all
          </Link>
        </div>
      ) : (
        <>
          <h1 className="text-sm font-bold leading-8 mb-20 md:mb-6 text-left">
            {`Dashboard > All transaction`}
          </h1>

          <div className="mb-10 lg:mb-12">
            <PageIntro title={"Wallet Trnsactions"} description={""} />
          </div>
        </>
      )}

      <Table
        header={tableHeader}
        label={!pathname.includes("transactions") ? 'Recent Transaction' : ''}
        column={modifiedData}
      />

      <section className="w-full py-5">
        <Pagination
          rangeStart={
            totalCount !== 0
              ? (page || 0) * ((pageSize || 0) * pageColumn) + 1
              : 0
          }
          totalCount={totalCount}
          currentPage={(page || 0) + 1}
          pageSize={pageSize}
          pageColumn={pageColumn}
          siblingCount={siblingCount}
          onPageChange={handlePageChange}
          className="pt-9"
        />
      </section>

      {!!selectedData && <WalletTransactionDetailModal open={!!selectedData} close={() => setSelectedData(undefined)} data={selectedData} />}
    </div>
  );
};

export default TransactionTable;
