import {
  selectUserState,
  addUserProfile,
} from "src/lib/features/auth/authSlice";
import { useAppDispatch, useTypedSelector } from "src/lib/store/store";

const useStateHook = () => {
  const dispatch = useAppDispatch();
  const { accessToken, user } = useTypedSelector(selectUserState);

  const handleStateUpdate = (updatedUser: any) => {
    if (!updatedUser) return
    updatedUser.extraDetail = user?.extraDetail;
    const userInfo = {
      accessToken: accessToken,
      user: updatedUser,
    };
    dispatch(addUserProfile(userInfo));
  };

  return {
    handleStateUpdate,
  };
};

export default useStateHook;
