import { AxiosError } from "axios";
import useSWR from "swr";

import axiosInstance from "src/config/axios";
import {
  ActiveProjectDetail,
  Invite,
  Invites,
  JobDetail,
  JobOffer,
  JobOffers,
  MatchedJobs,
} from "./job.type";
import useSWRMutation from "swr/mutation";

export const useMatchedJobsQuery = (queryParams: string) => {
  const { data, error, isLoading, mutate } = useSWR<
    MatchedJobs,
    AxiosError,
    any
  >([`/dev/getAllMyMatchedJobs`, queryParams], ([url, queryParams]: any) =>
    axiosInstance.get(url + queryParams).then((res) => res.data.data)
  );

  return {
    matchedJobs: data,
    isLoading,
    error,
    getMatchedJobs: mutate,
  };
};

export const useActiveProjectQuery = (queryParams: string) => {
  const { data, error, isLoading, mutate } = useSWR<
    ActiveProjectDetail["data"],
    AxiosError,
    any
  >([`/dev/getAllMyActiveJobs`, queryParams], ([url, queryParams]: any) =>
    axiosInstance.get(url + queryParams).then((res) => res.data.data)
  );

  return {
    activeProjects: data,
    isLoading,
    error,
    getActiveProjects: mutate,
  };
};

export const useAJobOfferQuery = (id: string) => {
  const { data, error, isLoading, mutate } = useSWR<
    JobOffer,
    AxiosError,
    string
  >(`/dev/getAnOfferedJob/${id}`, (url) =>
    axiosInstance.get(url).then((res) => res.data.data)
  );

  return {
    jobOffer: data,
    isLoading,
    error,
    getJobOffer: mutate,
  };
};

export const useJobOffersQuery = (page: number, status: string) => {
  const { data, error, isLoading, mutate } = useSWR<
    JobOffers["data"],
    AxiosError,
    [string, string]
  >(
    [`/dev/getAllMyOfferedJobs/${status}`, `?page=${page}`],
    ([url, queryParams]) =>
      axiosInstance.get(url + queryParams).then((res) => res.data.data)
  );

  return {
    jobOffers: data,
    isLoading,
    error,
    getJobOffers: mutate,
  };
};

export const useNewInvitesQuery = () => {
  const { data, error, isLoading, mutate } = useSWR<
    JobOffers["data"],
    AxiosError,
    `/dev/getAllMyNewInvites`
  >(`/dev/getAllMyNewInvites`, (url: string) =>
    axiosInstance.get(url).then((res) => res.data.data)
  );

  return {
    newInvites: data,
    isLoading,
    error,
    getNew: mutate,
  };
};

export const useJobDetailQuery = (id: string) => {
  const { data, error, isLoading, mutate } = useSWR<
    JobDetail,
    AxiosError,
    string
  >(`/dev/getAJobDetails/${id}`, (url: string) =>
    axiosInstance.get(url).then((res) => res.data.data[0])
  );

  return {
    jobDetail: data,
    isLoading,
    error,
    getJobDetail: mutate,
  };
};

export const useUserInviteQuery = (id: string) => {
  const { data, error, isLoading, mutate } = useSWR<Invite, AxiosError, string>(
    `/dev/getInviteById/${id}`,
    (url: string) => axiosInstance.get(url).then((res) => res.data.data)
  );

  return {
    jobInvite: data,
    isLoading,
    error,
    getJobInvite: mutate,
  };
};

export const useUserInvitesQuery = (page: number) => {
  const { data, error, isLoading, mutate } = useSWR<
    Invites["data"],
    AxiosError,
    [string, string]
  >(["/dev/getAllMyNewInvites", `?page=${page}`], ([url, queryParams]) =>
    axiosInstance.get(url + queryParams).then((res) => res.data.data)
  );

  return {
    jobInvites: data,
    isLoading,
    error,
    getJobInvites: mutate,
  };
};

export const useCbtParamsRealnameQuery = ({
  id,
  section,
}: {
  id: string;
  section: string;
}) => {
  const { data, error, isLoading, mutate } = useSWR<
    JobOffers,
    AxiosError,
    string
  >(`/dev/all/getCbtParamsRealname/${id}/${section}`, (url: string) =>
    axiosInstance.get(url).then((res) => res.data)
  );

  return {
    cbtParamsRealnameQuery: data,
    isLoading,
    error,
    getCbtParamsRealnameQuery: mutate,
  };
};

export const useAcceptOfferMutation = (id: any) => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    any,
    AxiosError,
    string,
    any,
    any
  >(
    `/dev/acceptOffer/${id}`,
    (url: string, { arg }) =>
      axiosInstance.put(url, arg).then((res) => res.data),
    {
      onSuccess: (res) => {
        return true;
      },
      onError: (error: any) => {
        return false;
      },
    }
  );

  return {
    data,
    isLoading: isMutating,
    acceptOffer: trigger,
  };
};

export const useRejectOfferMutation = (id: any) => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    any,
    AxiosError,
    string,
    any,
    any
  >(
    `/dev/rejectOffer/${id}`,
    (url: string, { arg }) =>
      axiosInstance.put(url, arg).then((res) => res.data),
    {
      onSuccess: (res) => {
        return true;
      },
      onError: (error: any) => {
        return false;
      },
    }
  );

  return {
    data,
    isLoading: isMutating,
    rejectOffer: trigger,
  };
};

export const useAcceptInviteMutation = (id: string) => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    any,
    AxiosError,
    string,
    any,
    any
  >(
    `/dev/acceptInvite/${id}`,
    (url: string, { arg }) =>
      axiosInstance.put(url, arg).then((res) => res.data),
    {
      onSuccess: (res) => {
        return true;
      },
      onError: (error: any) => {
        return false;
      },
    }
  );

  return {
    data,
    isLoading: isMutating,
    acceptInvite: trigger,
  };
};

export const useRejectInviteMutation = (id: string) => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    any,
    AxiosError,
    string,
    any,
    any
  >(
    `/dev/rejectInvite/${id}`,
    (url: string, { arg }) =>
      axiosInstance.put(url, arg).then((res) => res.data),
    {
      onSuccess: (res) => {
        return true;
      },
      onError: (error: any) => {
        return false;
      },
    }
  );

  return {
    data,
    isLoading: isMutating,
    rejectInvite: trigger,
  };
};
