import React, { ReactNode } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";
// import { SkeletonTheme } from 'react-loading-skeleton';
// import SkeletonBox from '../SkeletonBox/SkeletonBox';

type Props = {
  header: {
    title: string;
    key: string;
  }[];
  column: (string | React.JSX.Element | null)[][] | undefined;
  label?: string;
  defaultDisplay?: ReactNode;
};

const Table = ({ header, column, label, defaultDisplay }: Props) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="rounded-3xl border border-[rgba(141, 137, 137, 0.50] p-4 lg:p-8 shadow-table">
      {/* HEADING */}
      {label && (
        <div className="pb-6 border-b border-[#8d8989] border-opacity-80 ">
          <h2
            className={`${
              theme === "dark" ? "text-[#EDEDED]" : "text-primary"
            } 'text-2xl font-extrabold text-left'`}
          >
            {label}
          </h2>
        </div>
      )}

      <div className="flex flex-col max-h-[60vh] overflow-y-auto">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full">
            <div className="overflow-hidden">
              <table className=" min-w-full text-left text-sm font-light">
                <thead className="font-medium border-b-[0.5px] border-[#8d8989] border-opacity-80">
                  <tr className="px-2 mx-auto">
                    {header?.map((item) => (
                      <th
                        scope="col"
                        className="pt-6 pb-3 font-semibold mx-auto"
                        key={item.key}
                      >
                        {item.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                {column?.length !== 0 && (
                  <tbody>
                    {column?.map((row, i) => (
                      <tr
                        className="transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600 border-b-[0.5px] border-[#8d8989] border-opacity-80"
                        key={i}
                      >
                        {row?.map((item, index) => (
                          <td
                            className="whitespace-nowrap py-3 pr-4"
                            key={index}
                          >
                            <div>{item}</div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              {column?.length === 0 && (
                <div className="w-full flex items-center justify-center py-10">
                  {defaultDisplay}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
