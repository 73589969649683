import { AxiosError } from "axios";
import axiosInstance from "src/config/axios";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { GetUserProfileResponse, Level, OverAllRating, Stack, WorkHistoryResponse } from "./profile.type";

export const useUserOverallRatingQuery = () => {
  const { data, error, isLoading, mutate } = useSWR<
    OverAllRating,
    AxiosError,
    string
  >(`/dev/getMyOverAllRating`, (url: string) =>
    axiosInstance.get(url).then((res) => res.data?.data)
  );

  return {
    overallRating: data,
    isLoading,
    error,
    getOverAllRating: mutate,
  };
};

export const useLevelsQuery = () => {
  const { data, error, isLoading, mutate } = useSWR<
    Level[],
    AxiosError,
    string
  >(`/dev/getLevels`, (url: string) =>
    axiosInstance.get(url).then((res) => res.data?.data)
  );

  return {
    levels: data,
    isLoading,
    error,
    getLevels: mutate,
  };
};

export const useStacksQuery = () => {
  const { data, error, isLoading, mutate } = useSWR<
    Stack[],
    AxiosError,
    string
  >(`/dev/getStacks`, (url: string) =>
    axiosInstance.get(url).then((res) => res.data?.data)
  );

  return {
    stacks: data,
    isLoading,
    error,
    getStacks: mutate,
  };
};

export const useUserAvailableMutation = (id: string) => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    any,
    AxiosError,
    string,
    any,
    any
  >(
    `/dev/iamAvailable/${id}`,
    (url: string, { arg }) =>
      axiosInstance.put(url, arg).then((res) => res.data),
    {
      onSuccess: (res) => {
        return true;
      },
      onError: (error: any) => {
        return false;
      },
    }
  );

  return {
    data,
    isLoading: isMutating,
    userAvailable: trigger,
  };
};

export const useUserNotAvailableMutation = (id: string) => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    any,
    AxiosError,
    string,
    any,
    any
  >(
    `/dev/iamNotAvailable/${id}`,
    (url: string, { arg }) =>
      axiosInstance.put(url, arg).then((res) => res.data),
    {
      onSuccess: (res) => {
        return true;
      },
      onError: (error: any) => {
        return false;
      },
    }
  );

  return {
    data,
    isLoading: isMutating,
    userNotAvailable: trigger,
  };
};

export const useUpdateProfileMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    GetUserProfileResponse,
    AxiosError,
    string,
    FormData,
    any
  // >(`/all/uploadcbtquestionsheet/version-control`, (url: string, { arg }) =>
  //   axiosInstance.put(url, arg).then((res) => res.data)
  // );
  >(`/dev/updateTalentProfile`, (url: string, { arg }) =>
    axiosInstance.put(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    updateProfile: trigger,
  };
};

export const useUpdateStackMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    GetUserProfileResponse,
    AxiosError,
    string,
    FormData,
    any
  >(`/dev/updateTalentStack`, (url: string, { arg }) =>
    axiosInstance.put(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    updateStack: trigger,
  };
};

export const useUpdateLanguagesMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    GetUserProfileResponse,
    AxiosError,
    string,
    FormData,
    any
  >(`/dev/updateTalentLanguages`, (url: string, { arg }) =>
    axiosInstance.put(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    updateLanguages: trigger,
  };
};

export const useUpdateFrameworksMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    GetUserProfileResponse,
    AxiosError,
    string,
    FormData,
    any
  >(`/dev/updateTalentFrameworks`, (url: string, { arg }) =>
    axiosInstance.put(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    updateFrameworks: trigger,
  };
};

export const useUpdateSkillsMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    GetUserProfileResponse,
    AxiosError,
    string,
    FormData,
    any
  >(`/dev/updateTalentSkills`, (url: string, { arg }) =>
    axiosInstance.put(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    updateSkills: trigger,
  };
};

export const useUpdateProfilePhotoMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    GetUserProfileResponse,
    AxiosError,
    string,
    FormData,
    any
  >(`/dev/updateTalentProfile`, (url: string, { arg }) =>
    axiosInstance.put(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    updateProfile: trigger,
  };
};

export const useWorkHistoryQuery = (page: number) => {
  const { data, error, isLoading, mutate } = useSWR<
    WorkHistoryResponse["data"],
    AxiosError,
    ["/dev/getMyWorkHistory", string]
  >(["/dev/getMyWorkHistory", `?page=${page}`], ([url, queryParams]) =>
    axiosInstance.get(url + queryParams).then((res) => res.data.data)
  );

  return {
    workHistory: data,
    isLoading,
    error,
    getWorkHistory: mutate,
  };
};
