import { useContext } from 'react';
import Navbar from '../components/Navigation/Navbar';
import SideNav from '../components/Navigation/SideNav';
import { ThemeContext } from '../context/ThemeContext';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Layout = ({ children }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className='relative'>
      <header className='mb-24 lg:mb-28'>
        <Navbar />
      </header>
      <div className='relative min-h-screen flex lg:gap-x-60 xl:gap-x-80 '>
        <div className='hidden lg:block'>
          <div
            className={`fixed left-0 lg:w-60 xl:w-80 z-20 h-screen overflow-y-scroll scrollbar-hide ${theme === 'dark' ? 'bg-[#444444]' : 'bg-[#D9D9D9]'
              }`}
          >
            <SideNav />
          </div>
        </div>
        <main className='relative w-full pt-11 px-4 pb-20 sm:px-8 md:p-12 min-h-screen overflow-x-hidden'>
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
